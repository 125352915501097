<template>
    <div id="emailslist">
        <div class="conversation" v-if="parent">
            <div class="header">{{ data.subject }}</div>

            <Conversation
                v-if="data.replies && data.replies.length > 0"
                v-for="reply in data.replies"
                :parent="false"
                :data="reply"
            ></Conversation>
            <collapse :selected="false">
                <div slot="collapse-header">
                    <span class="collapseHeader"
                        >{{ formatEmailHeader }}
                        <span class="emailExcerpt">{{ formatEmailExcerpt }}</span>
                    </span>
                    <span class="cllapsTime">
                        <span class="emailDatePrefix">{{ $moment(data.date).format('Do MMM H:mm') }}</span>
                        ({{ $moment(data.date).fromNow() }})</span
                    >
                </div>
                <div slot="collapse-body">
                    <div class="emailBody">
                        <p class="bodyText" v-html="!data.text ? $t('components.empty-msg') : data.text"></p>
                    </div>
                </div>
            </collapse>
        </div>
        <div v-else>
            <collapse :selected="false">
                <div slot="collapse-header">
                    <span class="collapseHeader"
                        >{{ formatEmailHeader }}
                        <span class="emailExcerpt">{{ formatEmailExcerpt }}</span>
                    </span>
                    <span class="cllapsTime">
                        <span class="emailDatePrefix">{{ $moment(data.date).format('Do MMM H:mm') }}</span>
                        ({{ $moment(data.date).fromNow() }})</span
                    >
                </div>
                <div slot="collapse-body">
                    <div class="emailBody">
                        <p class="bodyText" v-html="!data.text ? $t('components.empty-msg') : data.text"></p>
                    </div>
                </div>
            </collapse>
        </div>
    </div>
</template>

<script>
function cutString(text) {
    if (String(text).trim().length < 9) {
        return text;
    }
    const wordsToCut = 9;
    const wordsArray = text.split(' ');
    if (wordsArray.length > wordsToCut) {
        let strShort = '';
        for (let i = 0; i < wordsToCut; i++) {
            strShort += wordsArray[i] + ' ';
        }
        return strShort + '...';
    } else {
        return text;
    }
}
import Collapse from 'vue-collapse';
import size from 'lodash.size';

export default {
    name: 'Conversation',

    props: {
        data: {
            type: Object,
        },
        parent: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    components: {
        Collapse,
    },
    data() {
        return {
            isParent: null,
        };
    },
    computed: {
        hasReplies() {
            return this.data.replies != null && this.data.replies.length > 0;
        },

        formatEmailSubject: function () {
            return this.$t('components.subject') + ': ' + this.data.subject;
        },
        formatEmailHeader: function () {
            let to = this.data.to;
            if (Array.isArray(to)) {
                to = to.map((t) => t.name).join(', ');
            }
            return to;
        },
        formatEmailExcerpt() {
            const temp = document.createElement('div');
            temp.innerHTML = this.data.text;
            const sanitized = temp.textContent || temp.innerText;
            return !this.data.text ? this.$t('components.empty-msg') : cutString(sanitized);
        },
    },
    methods: {},
    created() {
        this.isParent = this.parent;
    },
};
</script>

<style lang="css">
.conversation {
    border: 1px solid #d7d7d7;
    padding: 10px;
    margin-bottom: 10px;
}

.conversation .header {
    font-weight: 500;
    margin-bottom: 10px;
}

.emailBody {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
}

.emailBody .bodyIcon {
    display: table-cell;
    vertical-align: middle;
    width: 1%;
    padding-right: 10px;
}

.emailBody .subEmails {
    display: table-cell;
    vertical-align: middle;
}

.bodyIcon .fa {
    vertical-align: middle;
    webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.bodyText {
    display: inline-block;
    vertical-align: middle;
    /*padding-left: 10px;*/
}

.collapse-header.touchable {
    cursor: pointer;
}

.collapse .collapse-header::before {
    top: calc(50% - 1em) !important;
}

.collapse .collapse-header {
    padding: 10px 10px 10px 40px !important;
    background: rgba(#f2f2f2, 0.92) !important;
    border-top: 1px solid #d7d7d7;
    /*border-bottom: 1px solid #d1d1d1;*/
    border-radius: 0 !important;
}

.collapseHeader {
    font-weight: 500;
    font-size: 0.85em;
    color: black;
}

.cllapsTime {
    font-size: 0.8em;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.78);
}

.collapse .collapse-content-box {
    padding: 15px 20px !important;
    font-size: 13px;
}

.collapse {
    margin-bottom: 0 !important;
}

.emailExcerpt {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    padding-left: 5px;
}

.emailDatePrefix {
    text-transform: capitalize;
}

.emailList.replyContainer {
    margin-top: 10px;
    display: table;
    width: 100%;
}

.ql-tooltip.ql-editing {
    left: 0 !important;
}

/*.replyContainer .sendReplyBtn {
  margin: 0px 0px 0px 20px !important;
  display: table-cell;
  vertical-align: middle;
  float: none;
}

.replyContainer .quill-editor {
  display: table-cell;
  vertical-align: middle;
}*/

.replyButton {
    margin-top: 20px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
	.fade-leave-to
	/* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
